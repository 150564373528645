<script setup lang="ts">
import { twMerge } from 'tailwind-merge'

/**
 * UiBox
 *
 * A lightweight UiCard alternative to quickly layout content
 *
 * Props:
 *
 * - variant: for visual style
 * - layout: for quick layout
 * - class: for overrides, i.e. md:grid-cols-4 bg-amber-100
 *
 * @see https://github.com/forged-com/forgd/pull/2565
 */
const props = withDefaults(defineProps<{
  variant?: 'none' | 'white' | 'neutral' | 'border' | 'white-border' | 'neutral-border'
  layout?: 'stack' | 'divide' | 'col' | 'row' | 'wrap' | 'grid'
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}>(), {
  variant: 'border',
  layout: '',
  size: 'md',
})

const classes = computed(() => {
  // appearance
  const color = String(props.variant.match(/white|neutral|none/))
  const border = props.variant.includes('border') || props.layout === 'divide'
  const colors = {
    white: 'bg-white',
    neutral: 'bg-neutral-600/30',
    none: '',
  }

  // layout
  const size = props.size
  const space = {
    xs: 'space-y-1',
    sm: 'space-y-2',
    md: 'space-y-4',
    lg: 'space-y-5',
    xl: 'space-y-6',
  }
  const padding = {
    xs: 'p-1',
    sm: 'p-2',
    md: 'p-4',
    lg: 'p-5',
    xl: 'p-6',
  }
  const gap = {
    xs: 'gap-2',
    sm: 'gap-2',
    md: 'gap-4',
    lg: 'gap-5',
    xl: 'gap-6',
  }
  const layout = {
    stack: space[size],
    col: `flex flex-col items-center`,
    row: 'flex justify-around',
    wrap: 'flex flex-wrap',
    grid: 'grid grid-cols-2',
    divide: 'divide-y divide-solid divide-neutral-600',
  }

  // classes
  const base = [
    'rounded-lg border-neutral-600',
    border ? 'border' : '',
    colors[color],
    layout[props.layout],
  ]

  if (props.layout === 'divide') {
    const paddingDivided = {
      xs: '[&>*]:p-1',
      sm: '[&>*]:p-2',
      md: '[&>*]:p-4',
      lg: '[&>*]:p-5',
      xl: '[&>*]:p-6',
    }
    return twMerge([
      base,
      paddingDivided[size],
    ])
  }

  return twMerge([
    base,
    padding[size],
    gap[size],
  ])
})
</script>

<template>
  <div data-ui="UiBox" :class="classes">
    <slot />
  </div>
</template>
